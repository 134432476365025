@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');







.slider_banner .slider-wrapper {
  position: relative;
    height: 20vh;
    overflow: hidden;
    border-radius: 8px;
}

.slider_banner .slide {
  height: 22vh !important;
  background-size: cover !important;
}

.slider_banner .slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
}
#products .slide{
  position: relative !important;
}

#products .slide p{
 opacity: 1;
}
#products .slide button{
 opacity: 1;
}
 .slider_banner .previousButton, .nextButton {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
		background: url('http://svgshare.com/i/41_.svg') no-repeat center center / 16px;
		width: 32px;
		height: 32px;
		text-indent: -9999px;
		cursor: pointer;
    display: none;
}


.slider_banner .previousButton:hover, .nextButton:hover {
  background: url('http://svgshare.com/i/41_.svg') no-repeat center center / 16px;
}

.slider_banner .previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
          transform: rotate(180deg) translateY(calc(50% + 0px));
}

 .slider_banner .previousButton:hover {
  left: -10px;
}

.slider_banner .nextButton {
  right: 0;
}

 .slider_banner .nextButton:hover {
  right: -10px;
}

.slider_banner .slider-content {
  text-align: center;
}

.slider_banner .slider-content .inner {

  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

 .slider_banner .slider-content .inner button{
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.slider_banner .slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 1;
}

 .slider_banner .slider-content .inner p {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 1.5;
  margin: 0px auto 0px;
  max-width: 640px;
}
img.online_icon {
    margin-top: -4px;
}
.slider_banner .slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider_banner .slider-content section span {
  color: #FFFFFF;
}

 .slider_banner .slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider_banner .slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider_banner .slider-content section span strong {
  color: #FFFFFF;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider_banner .slider-wrapper, .slide {
    height: 23vh;
  }    
}

@media (max-width: 640px) {
  .slider_banner .slider-wrapper, .slide {
   height: 20vh;
  }    
}

@media (max-height: 600px) {
  .slider_banner .slider-content .inner h1 {
    font-size: 22px;
  }    
}

@media (max-width: 640px) {
  .slider_banner .slider-content .inner h1 {
    font-size: 22px;
  }
}
.d-inline{
  display: inline;
}
/* Firefox old*/
@-moz-keyframes blink {
    0% {
        opacity:1;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
} 

@-webkit-keyframes blink {
    0% {
        opacity:1;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
/* IE */
@-ms-keyframes blink {
    0% {
        opacity:1;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
} 
/* Opera and prob css3 final iteration */
@keyframes blink {
    0% {
        opacity:1;
    }
    50% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
} 
img.online_icon {
        -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
    -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
    -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
    animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}
